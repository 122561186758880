/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 100px; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px; /* Set the fixed height of the footer here */
  line-height: 80px; /* Vertically center the text there */
  /* background-color: #f5f5f5; */
}

/*
  This works around a resource conflict between bootstrap and ngx-toastr both making use of the .toastr class.
  See: https://stackoverflow.com/questions/53989445/ngx-toastr-toast-not-showing-in-angular-7
*/
#toast-container > div {
  opacity:1;
}
/********* Added by RDH 03/21/2019 **********/
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.phoneFormat {
  font-size: 14px;
}
.phoneFormatBold {
  font-weight: bold;
}
.phoneTitle {
  width: 130px;
}
.padLeft20 {
  padding-left: 20px;
}
.padLeft6 {
  padding-left: 6px;
}
.italicize {
  font-style: italic;
}
.fontNavy {
  color: navy;
}
.stayRight {
  float: right;
  padding-right: 100px;
}
.inputHeight {
  height: 1.75em;
  margin: 0;
  padding: 0px 0px 0px 3px;
}
.labelHeight {
  height: 1.25em;
  line-height: 1.25em;
}
.select-box {
  height: 1.5em;
  margin: 0;
  padding: 0;  
}
/********* End of Added by RDH 03/21/2019 **********/
